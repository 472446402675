@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

body::-webkit-scrollbar { 
    display: none; 
}

.containerPage{
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
}

.opac{
    background: rgba(40, 43, 40, 0.0)
}
.opak{
    background: rgba(40, 43, 40, 0.8)
}

.gray{
    background-color: #19171a;
}
.info{
    background: rgba(40, 43, 40, 0.9);
    backdrop-filter: blur(5px);
}
  
#home{
background: rgb(203,33,35);
background: linear-gradient(90deg, rgba(203,33,35,1) 0%, rgba(203,33,35,1) 33%, rgba(203,33,35,0) 92%);
}

.fp-watermark {
    display: none;
}

.blur{
    filter:blur(5px);
}

.adresa{
    border-top: 2px solid rgb(179, 109, 4);
    border-bottom: 2px solid rgb(179, 109, 4);
}

.link{
    cursor: pointer;
}    
.input-design{
    border-radius: 3px;
    height: 35px;
    margin-top: 5px;
    color: black;
    padding-left: 1rem;
}

#contact-container{
    /* overflow: scroll; */
    overflow-x: hidden;
    background: rgb(203,33,35);
    background: linear-gradient(18deg, rgba(203,33,35,1) 0%, rgba(128,27,28,1) 0%, rgba(203,33,33,0) 72%);
}

#contact-container::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

h1{
    font-family: 'Montserrat', sans-serif;
}

body{
    font-family: 'Roboto', sans-serif;
}
